@charset "utf-8";
/* CSS Document */
.jumbotron {
    margin: 0;
    padding: 0;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
}

html {
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
}


body {
    background: #fafafa;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {

    [class*="col-"] {
        min-width: 100%;
        width: 100%;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 960px;
    }
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5em;
}

label, input, select {
    font-family: 'Montserrat', sans-serif;
}

button {
    font-family: 'Montserrat', sans-serif;
}

p, li, a {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5em;
}

span {
    font-family: 'Montserrat', sans-serif;
}

a {
    transition: 0.6s;
}

.clearfix {
    padding: 10px 0;
}

.nopadding {
    padding: 0;
}

#header-fixed {
    transition: all 0.15s linear;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1;
}

    #header-fixed.active {
        box-shadow: 0 8px 6px -6px #ddd;
    }

@media only screen and (max-width: 768px) {
    #header-fixed {
        transition: all 0.15s linear;
        position: -webkit-relative; /* Safari */
        position: relative;
        top: 0;
        z-index: 1;
    }

        #header-fixed.active {
            box-shadow: 0 8px 6px -6px #ddd;
        }

    #header-logo-fixed {
        transition: all 0.15s linear;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index: 9999;
    }

        #header-logo-fixed.active {
            box-shadow: 0 8px 6px -6px #ddd;
        }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    #header-fixed {
        transition: all 0.15s linear;
        position: -webkit-relative; /* Safari */
        position: relative;
        top: 0;
        z-index: 1;
    }

        #header-fixed.active {
            box-shadow: 0 8px 6px -6px #ddd;
        }

    #header-logo-fixed {
        transition: all 0.15s linear;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index: 9999;
    }

        #header-logo-fixed.active {
            box-shadow: 0 8px 6px -6px #ddd;
        }
}

/* Header Navbar section */
.header-navbar-section {
    background: #ffffff;
    height: 40px;
}

.header-account-menu {
    float: right;
}

    .header-account-menu ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

        .header-account-menu ul li {
            display: inline-block;
            line-height: 40px;
            margin-right: 20px;
        }

            .header-account-menu ul li .class-account-name-header {
                margin-right: 0px;
                left: 410px;
                top: 8px;
            }

                .header-account-menu ul li .class-account-name-header h1 {
                    color: #373F51;
                    font-size: 11px;
                    font-weight: 200;
                    letter-spacing: 0;
                    line-height: 24px;
                    position: relative;
                }

                    .header-account-menu ul li .class-account-name-header h1 b {
                        font-weight: 700;
                    }

                    .header-account-menu ul li .class-account-name-header h1 i {
                        padding-right: 5px;
                        color: #004282;
                        font-size: 15px;
                    }

                    .header-account-menu ul li .class-account-name-header h1 span {
                        font-weight: 300;
                        color: #32353e;
                        font-size: 11px;
                    }

            .header-account-menu ul li:last-child {
                margin-right: 0;
            }

            .header-account-menu ul li a {
                color: #016AB7;
                font-size: 11px;
                letter-spacing: 0;
                line-height: 24px;
                position: relative;
            }

                .header-account-menu ul li a:hover {
                    text-decoration: none;
                    color: #016ab7;
                }

.class-account-name-mobile-header {
    display: none;
}

@media only screen and (max-width: 768px) {
    .class-account-name-header {
        display: none;
    }

    .header-navbar-section {
        display: none;
    }

    .header-account-menu {
        display: none;
        height: auto;
    }

    .class-account-name-mobile-header {
        display: block;
        margin-top: 30px;
        padding-bottom: 10px;
        text-align: left;
        border-bottom: 1px solid #ECEDEF;
    }

        .class-account-name-mobile-header h1 {
            color: #373F51;
            font-size: 11px;
            font-weight: 200;
            letter-spacing: 0;
            line-height: 24px;
            position: relative;
        }

            .class-account-name-mobile-header h1 b {
                font-weight: 700;
            }

            .class-account-name-mobile-header h1 i {
                padding-right: 5px;
                color: #004282;
                font-size: 15px;
            }

            .class-account-name-mobile-header h1 span {
                font-weight: 300;
                color: #32353e;
                font-size: 11px;
            }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .class-account-name-header {
        display: none;
    }

    .header-navbar-section {
        display: none;
    }

    .header-account-menu {
        display: none;
        height: auto;
    }

    .class-account-name-mobile-header {
        display: block;
        margin-top: 30px;
        padding-bottom: 10px;
        text-align: left;
        border-bottom: 1px solid #ECEDEF;
    }

        .class-account-name-mobile-header h1 {
            color: #373F51;
            font-size: 11px;
            font-weight: 200;
            letter-spacing: 0;
            line-height: 24px;
            position: relative;
        }

            .class-account-name-mobile-header h1 b {
                font-weight: 700;
            }

            .class-account-name-mobile-header h1 i {
                padding-right: 5px;
                color: #004282;
                font-size: 15px;
            }

            .class-account-name-mobile-header h1 span {
                font-weight: 300;
                color: #32353e;
                font-size: 11px;
            }
}


/* Header Section */
.header-section {
    height: 122px;
    background: linear-gradient(-45deg, #004282, #004282 60%, #fff 60%) #004282;
    border-bottom: 2px solid #004282;
}

.page-top-border {
}

.header-section .logo {
    padding-top: 14px;
}

    .header-section .logo p {
        display: none;
    }

.header-section .account-link {
    display: none;
}

@media only screen and (max-width: 768px) {
    .header-section {
        height: auto;
        background: none;
    }

        .header-section .logo {
            padding-top: 10px;
            text-align: center;
        }

            .header-section .logo img {
                text-align: center;
                margin: 0 auto;
            }

            .header-section .logo p {
                text-align: center;
                display: block;
                padding-top: 10px;
            }

                .header-section .logo p a {
                    color: #016AB7;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 24px;
                    position: relative;
                    font-weight: 500;
                }

        .header-section .account-link {
            display: block;
            padding-top: 15px;
            padding-bottom: 15px;
        }

            .header-section .account-link a {
                color: #016AB7;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 24px;
                position: relative;
            }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .header-section {
        height: auto;
        background: none;
    }

        .header-section .logo {
            padding-top: 10px;
            text-align: center;
        }

            .header-section .logo img {
                text-align: center;
                margin: 0 auto;
            }

            .header-section .logo p {
                text-align: center;
                display: block;
                padding-top: 10px;
            }

                .header-section .logo p a {
                    color: #016AB7;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 24px;
                    position: relative;
                    font-weight: 500;
                }

        .header-section .account-link {
            display: block;
            padding-top: 15px;
            padding-bottom: 15px;
        }

            .header-section .account-link a {
                color: #016AB7;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 24px;
                position: relative;
            }
}

.mobile-blue-background {
}

@media only screen and (max-width: 768px) {
    .mobile-blue-background {
        background: #004282;
        padding-top: 10px;
        padding-bottom: 35px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .mobile-blue-background {
        background: #004282;
        padding-top: 10px;
        padding-bottom: 35px;
    }
}

.header-section .static-menu {
    text-align: right;
    padding-top: 25px;
}

.mobile-blue-background {
}

.header-section .static-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

    .header-section .static-menu ul li {
        display: inline-block;
        margin-right: 1px;
        position: relative;
    }

        .header-section .static-menu ul li:after {
            padding-left: 10px;
            content: "/";
            color: #fff;
        }

        .header-section .static-menu ul li:last-child {
            margin-right: 0px;
        }

            .header-section .static-menu ul li:last-child:after {
                content: "";
                padding-left: 0;
            }

        .header-section .static-menu ul li a {
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            position: relative;
        }

@media only screen and (max-width: 768px) {
    .mobile-blue-background {
        background: #004282;
        padding-top: 10px;
        padding-bottom: 15px;
    }

    .header-section .static-menu {
        text-align: center;
        padding-top: 10px;
    }

        .header-section .static-menu ul li a {
            font-size: 13px;
        }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .mobile-blue-background {
        background: #004282;
        padding-top: 10px;
        padding-bottom: 15px;
    }

    .header-section .static-menu {
        text-align: center;
        padding-top: 10px;
    }

        .header-section .static-menu ul li a {
            font-size: 13px;
        }
}

.header-section .static-menu ul li a:hover {
    text-decoration: none;
}

.header-section .header-search-menu {
    padding-top: 25px;
}

    .header-section .header-search-menu h2 {
        color: #FFFFFF;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-transform: uppercase;
        padding-bottom: 18px;
    }

.header-search-menu-normal {
    display: block;
}

.header-search-menu-mobile {
    display: none;
}


.header-search-menu-mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .header-search-menu-mobile {
        display: block;
        float: right;
        margin-top: -50px;
    }

    .header-search-menu-normal {
        display: none;
    }

    .header-section .header-search-menu {
        padding-top: 15px;
        padding-left: 0px;
    }

        .header-section .header-search-menu h2 {
            color: #FFFFFF;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            text-transform: uppercase;
            padding-bottom: 18px;
        }


    .header-search-menu-mobile {
        display: block;
    }

    .dropdown {
        float: right;
        padding-top: 10px;
    }

        .dropdown button {
            background: transparent;
            border: none;
            box-shadow: none;
            color: #fff;
            outline: none;
        }

        .dropdown .dropdown-menu {
        }

        .dropdown button:active {
            outline: none;
        }

    .dropdown-toggle::after {
        border: none;
        font-size: 15px;
        font-family: 'fontawesome';
        content: "\f107";
        vertical-align: 0;
    }

    .dropdown.show button::after {
        transform: rotate(180deg);
    }



    .search-container-mobile {
        margin-top: 25px;
        border: 1px solid rgba(255,255,255,0.5);
        width: 100%;
        padding: 8px;
        border-radius: 100px;
    }

        .search-container-mobile input[type=text] {
            border-radius: 0;
            outline: none;
            box-shadow: none;
            background: transparent;
            border: none;
            width: 90%;
            padding: 0 20px;
            color: #fff;
        }

        .search-container-mobile button {
            background: transparent;
            color: #fff;
            outline: none;
            box-shadow: none;
            border: none;
        }

        .search-container-mobile ::-webkit-input-placeholder {
            color: rgba(255,255,255,0.5);
        }

        .search-container-mobile :-ms-input-placeholder {
            color: rgba(255,255,255,0.5);
        }

        .search-container-mobile ::placeholder {
            color: rgba(255,255,255,0.5);
        }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .header-search-menu-mobile {
        display: block;
        float: right;
        margin-top: -50px;
    }

    .header-search-menu-normal {
        display: none;
    }

    .header-section .header-search-menu {
        padding-top: 15px;
        padding-left: 0px;
    }

        .header-section .header-search-menu h2 {
            color: #FFFFFF;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            text-transform: uppercase;
            padding-bottom: 18px;
        }


    .header-search-menu-mobile {
        display: block;
    }

    .dropdown {
        float: right;
        padding-top: 10px;
    }

        .dropdown button {
            background: transparent;
            border: none;
            box-shadow: none;
            color: #fff;
            outline: none;
        }

        .dropdown .dropdown-menu {
        }

        .dropdown button:active {
            outline: none;
        }

    .dropdown-toggle::after {
        border: none;
        font-size: 15px;
        font-family: 'fontawesome';
        content: "\f107";
        vertical-align: 0;
    }

    .dropdown.show button::after {
        transform: rotate(180deg);
    }



    .search-container-mobile {
        margin-top: 25px;
        border: 1px solid rgba(255,255,255,0.5);
        width: 100%;
        padding: 8px;
        border-radius: 100px;
    }

        .search-container-mobile input[type=text] {
            border-radius: 0;
            outline: none;
            box-shadow: none;
            background: transparent;
            border: none;
            width: 90%;
            padding: 0 20px;
            color: #fff;
        }

        .search-container-mobile button {
            background: transparent;
            color: #fff;
            outline: none;
            box-shadow: none;
            border: none;
        }

        .search-container-mobile ::-webkit-input-placeholder {
            color: rgba(255,255,255,0.5);
        }

        .search-container-mobile :-ms-input-placeholder {
            color: rgba(255,255,255,0.5);
        }

        .search-container-mobile ::placeholder {
            color: rgba(255,255,255,0.5);
        }
}

.header-search-dropdown {
    display: none;
}

.header-section .header-search-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

    .header-section .header-search-menu ul li {
        display: inline-block;
        padding-right: 70px;
    }

        .header-section .header-search-menu ul li:last-child {
            padding-right: 0px;
        }

        .header-section .header-search-menu ul li a {
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-decoration: none;
        }

.header-section .splash-heading {
    text-align: right;
    color: #FFFFFF;
}

    .header-section .splash-heading h1 {
        height: 56px;
        width: 272px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 46px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 56px;
    }

    .header-section .splash-heading a{
        color: #FFFFFF;
        white-space: nowrap;
    }

@media only screen and (max-width: 768px) {
    .header-section .splash-heading {
        text-align: center;
        margin: 0 auto;
    }

        .header-section .splash-heading h1 {
            color: #fff;
            line-height: 56px;
            font-size: 46px;
            font-weight: bold;
            text-align: center;
            font-family: Montserrat;
            margin: 0 auto;
        }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .header-section .splash-heading {
        text-align: center;
        margin: 0 auto;
    }

        .header-section .splash-heading h1 {
            color: #fff;
            line-height: 56px;
            font-size: 46px;
            font-weight: bold;
            text-align: center;
            font-family: Montserrat;
            margin: 0 auto;
        }
}


/* return navigation Section */
.returnnav-section {
    height: 15px;
    background: #ffffff;
    color: #016AB7;
    font-size: 11px;
}


/* Account Content */
.class-account-name {
    padding-bottom: 45px;
}

    .class-account-name h1 {
        color: #373F51;
        font-size: 16px;
        font-weight: 200;
        letter-spacing: 0;
        line-height: 24px;
        position: relative;
    }

        .class-account-name h1 b {
            font-weight: 700;
        }

        .class-account-name h1 i {
            padding-right: 10px;
            color: #004282;
        }

        .class-account-name h1 span {
            font-weight: 300;
            color: #32353e;
            font-size: 14px;
        }

@media only screen and (max-width: 768px) {
    .class-account-name {
        padding-bottom: 45px;
        text-align: left;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .class-account-name {
        padding-bottom: 45px;
        text-align: left;
    }
}

#notification_box {
    /*display:none;*/
    background: #ffff;
    padding: 10px;
    position: absolute;
    top: 40px;
    left: 720px;
    z-index: 9999;
    width: 400px;
    min-height: 150px;
    padding: 30px;
    z-index: 1010;
    -webkit-box-shadow: 0 14px 28px 0 rgba(186,186,187,.35);
    box-shadow: 0 14px 28px 0 rgba(186,186,187,.35);
}

@media only screen and (max-width: 768px) {
    #notification_box {
        top: 183px;
        z-index: 9999;
        left: 0;
        width: 100%;
        padding: 30px 20px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    #notification_box {
        top: 183px;
        z-index: 9999;
        left: 0;
        width: 100%;
        padding: 30px 20px;
    }
}

#notification_box .notification_close {
    position: absolute;
    top: 15px;
    right: 20px;
    text-align: center;
    border-radius: 100px;
}

    #notification_box .notification_close i {
        text-align: center;
        color: #016AB7;
        font-size: 20px;
    }

#notification_box h2 {
    font-size: 17px;
    color: #004282;
    font-weight: 700;
    padding-bottom: 10px;
}

#notification_box p {
    font-weight: 200;
    font-size: 14px;
    color: #373F51;
}

#member_search_box {
    display: none;
}

#office_search_box {
    display: none;
}

#association_search_box {
    display: none;
}


.member_search_close {
    position: absolute;
    top: 25px;
    right: 50px;
}

    .member_search_close i {
        color: #fff;
    }

.search-container {
    border: 1px solid rgba(255,255,255,0.5);
    width: 400px;
    padding: 5px;
    border-radius: 100px;
}

    .search-container input[type=text] {
        border-radius: 0;
        outline: none;
        box-shadow: none;
        background: transparent;
        border: none;
        width: 350px;
        padding: 0 20px;
        color: #fff;
    }

    .search-container button {
        background: transparent;
        color: #fff;
        outline: none;
        box-shadow: none;
        border: none;
    }

    .search-container ::-webkit-input-placeholder {
        color: rgba(255,255,255,0.5);
    }

    .search-container :-ms-input-placeholder {
        color: rgba(255,255,255,0.5);
    }

    .search-container ::placeholder {
        color: rgba(255,255,255,0.5);
    }


/* Account Chart title */

.account-chart-title {
    padding-bottom: 20px;
}

    .account-chart-title h1 {
        color: #373F51;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-transform: uppercase;
    }

.account-chart-container {
    margin-bottom: 60px;
    background: #fff;
    box-shadow: 0 20px 50px 0 rgba(0,66,130,0.08);
    border-radius: 0px;
    padding: 10px;
}

@media only screen and (max-width: 768px) {
    .account-chart-container:last-child {
        margin-bottom: 40px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .account-chart-container:last-child {
        margin-bottom: 40px;
    }
}

.blue-color {
    background: #004183;
}

.white-color {
    background: #fff;
}

/* btn */
.btn-view-more-insights {
    margin-bottom: 60px;
    float: right;
}

    .btn-view-more-insights a {
        width: 179px;
        height: 39px;
        text-align: center;
        float: right;
        border: 1px solid #016AB7;
        color: #016AB7;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 39px;
        position: relative;
        text-decoration: none;
        border-radius: 100px;
    }

        .btn-view-more-insights a:before {
            content: "\f178";
            color: #016AB7;
            font-family: FontAwesome;
            display: inline-block;
            margin-right: 1.5em;
            width: 1em;
            font-weight: 100;
        }

@media only screen and (max-width: 768px) {
    .btn-view-more-insights {
        margin: 0 auto;
        margin-bottom: 40px;
        float: inherit;
        text-align: center;
    }

        .btn-view-more-insights a {
            width: 179px;
            height: 39px;
            float: none;
            display: inline-block;
            text-align: center;
            border: 1px solid #016AB7;
            color: #016AB7;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 39px;
            position: relative;
            text-decoration: none;
            border-radius: 100px;
        }

            .btn-view-more-insights a:before {
                content: "\f178";
                color: #016AB7;
                font-family: FontAwesome;
                display: inline-block;
                margin-right: 1.5em;
                width: 1em;
                font-weight: 100;
            }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .btn-view-more-insights {
        margin: 0 auto;
        margin-bottom: 40px;
        float: inherit;
        text-align: center;
    }

        .btn-view-more-insights a {
            width: 179px;
            height: 39px;
            float: none;
            display: inline-block;
            text-align: center;
            border: 1px solid #016AB7;
            color: #016AB7;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 39px;
            position: relative;
            text-decoration: none;
            border-radius: 100px;
        }

            .btn-view-more-insights a:before {
                content: "\f178";
                color: #016AB7;
                font-family: FontAwesome;
                display: inline-block;
                margin-right: 1.5em;
                width: 1em;
                font-weight: 100;
            }
}

/* Account Pages */
.account-top-margin {
    margin-top: 80px;
}

@media only screen and (max-width: 768px) {
    .account-top-margin {
        margin-top: 50px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .account-top-margin {
        margin-top: 50px;
    }
}










.account-card {
}

    .account-card .card {
        border: none;
        min-height: 100%;
        box-shadow: 0 20px 50px 0 rgba(0,66,130,0.08);
    }

        .account-card .card .card-body {
            padding: 0px;
            overflow: auto;
        }

.office-information-banner {
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.general-information-banner {
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.member-information-banner {
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.association-information-banner {
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.mls-information-banner {
    position: relative;
    background-size: cover;
    height: 100%;
    width: 100%;
}


.card-detail-container {
    padding: 40px;
}

.padding-left-less {
    padding-left: 0;
}

.card-detail-container h1 {
    color: #373F51;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 40px;
}

    .card-detail-container h1 .account-card-view-all {
        float: right;
        background: rgba(190,215,234,0.2);
        color: #016ab7;
        font-weight: 700;
        font-size: 15px;
        padding: 10px 20px;
        border-radius: 100px;
        text-decoration: none;
    }

@media only screen and (max-width: 768px) {
    .card-detail-container h1 {
        padding-bottom: 25px;
    }

        .card-detail-container h1 .account-card-view-all {
            display: none;
        }

    #office_information {
        display: block;
    }

    #member_information {
        display: block;
    }

    #general_information {
        display: block;
    }

    #association_information {
        display: block;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .card-detail-container h1 {
        padding-bottom: 25px;
    }

        .card-detail-container h1 .account-card-view-all {
            display: none;
        }

    #office_information {
        display: block;
    }

    #member_information {
        display: block;
    }

    #general_information {
        display: block;
    }

    #association_information {
        display: block;
    }
}

.card-detail-container.card-menu {
    padding-top: 30px;
    padding-left: 0px;
}

    .card-detail-container .card-menu ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

        .card-detail-container .card-menu ul li {
            padding-bottom: 13px;
        }

            .card-detail-container .card-menu ul li a {
                color: #016AB7;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                position: relative;
            }

@media only screen and (max-width: 768px) {
    .card-detail-container .card-menu ul li a {
        line-height: 30px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .card-detail-container .card-menu ul li a {
        line-height: 30px;
    }
}

.card-detail-container .card-menu ul li a:hover {
    text-decoration: none;
    font-weight: 600;
    color: #1C7DAA;
}


/* Search */
.menu-search-section {
    background: #32353e;
    padding: 10px;
}

    .menu-search-section form {
    }

        .menu-search-section form .form-control {
            height: 40px;
            border: none;
            background: #fff;
            border-radius: 0;
            box-shadow: none;
            outline: none;
            padding: 10px;
            margin-right: 20px;
        }

            .menu-search-section form .form-control:focus {
                outline: none;
            }

        .menu-search-section form .btn-search {
            background: #888787;
            width: 30%;
            margin: 0 5px;
            float: left;
            height: 40px;
            border: none;
            color: #fff;
            outline: none;
            box-shadow: none;
            outline: none;
        }

.search-bar1 {
    display: none;
}

.search-bar2 {
    display: none;
}

.search-bar3 {
    display: none;
}

.padding-less-right {
    padding-right: 0;
}

.padding-less-left {
    padding-left: 0;
}



/* Footer Section */
.footer-section {
    background: #373F51;
    padding: 51px 0;
}

@media only screen and (max-width: 768px) {
    .footer-section {
        background: #373F51;
        padding: 20px 0;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .footer-section {
        background: #373F51;
        padding: 20px 0;
    }
}

.footer-col-padding {
    padding: 0 35px;
}

@media only screen and (max-width: 768px) {
    .footer-col-padding {
        padding: 0 0;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .footer-col-padding {
        padding: 0 0;
    }
}

.footer-section .footer-logo {
    margin-top: 80px;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .footer-section .footer-logo {
        margin-top: 0;
        padding-bottom: 25px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .footer-section .footer-logo {
        margin-top: 0;
        padding-bottom: 25px;
    }
}

.footer-section .footer-logo img {
}

.footer-section .footer-title {
    margin-top: 30px;
    padding-bottom: 18px;
}

    .footer-section .footer-title h1 {
        color: #D6E1EB;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-transform: uppercase;
    }

@media only screen and (max-width: 768px) {
    .footer-section .footer-title {
        margin-top: 20px;
        text-align: center;
        padding-bottom: 0px;
    }

    .mobile-top-padding {
        padding-top: 40px;
    }

    .footer-section .footer-title h1 {
        color: #D6E1EB;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-transform: uppercase;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .footer-section .footer-title {
        margin-top: 20px;
        text-align: center;
        padding-bottom: 0px;
    }

    .mobile-top-padding {
        padding-top: 40px;
    }

    .footer-section .footer-title h1 {
        color: #D6E1EB;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-transform: uppercase;
    }
}


.footer-section .footer-contact-list {
}

    .footer-section .footer-contact-list ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

        .footer-section .footer-contact-list ul li {
            color: #ecedef;
            padding-bottom: 15px;
        }

            .footer-section .footer-contact-list ul li small.contact-info {
                padding-left: 40px;
                color: #FFFFFF;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 24px;
            }

            .footer-section .footer-contact-list ul li:last-child {
                padding-bottom: 0;
            }

            .footer-section .footer-contact-list ul li b.phone_number {
                font-size: 22px;
                font-weight: 500;
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 21px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 40px;
            }

            .footer-section .footer-contact-list ul li.icon-telephone:before {
                content: "\f095";
                color: #ecedef;
                font-size: 22px;
                font-family: FontAwesome;
                display: inline-block;
                margin-right: 0.9em;
                width: 0.9em;
            }

            .footer-section .footer-contact-list ul li.icon-email:before {
                content: "\f003";
                color: #ecedef;
                font-size: 20px;
                font-family: FontAwesome;
                display: inline-block;
                margin-right: initial;
                width: 0.6em;
                padding-right: 10px;
            }

            .footer-section .footer-contact-list ul li a {
                color: #ecedef;
                position: inherit;
                padding-left: 11.5px;
            }

                .footer-section .footer-contact-list ul li a:hover {
                    text-decoration: none;
                    color: #ecedef;
                }

@media only screen and (max-width: 768px) {
    .footer-section .footer-contact-list {
        text-align: center;
    }

        .footer-section .footer-contact-list ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

            .footer-section .footer-contact-list ul li {
                color: #ecedef;
                padding-bottom: 40px;
            }

                .footer-section .footer-contact-list ul li small.contact-info {
                    text-align: center;
                    padding-left: 0px;
                    color: #FFFFFF;
                    word-break: break-all;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 24px;
                }

                .footer-section .footer-contact-list ul li:last-child {
                    padding-bottom: 0;
                }

                .footer-section .footer-contact-list ul li b.phone_number {
                    font-weight: 500;
                    color: #FFFFFF;
                    font-family: Montserrat;
                    font-size: 21px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 40px;
                }

                .footer-section .footer-contact-list ul li.icon-telephone:before {
                    content: "\f095";
                    color: #ecedef;
                    font-size: 21px;
                    font-family: FontAwesome;
                    display: inline-block;
                    margin-right: 0.9em;
                    width: 0.9em;
                }

                .footer-section .footer-contact-list ul li.icon-email:before {
                    content: "\f003";
                    color: #ecedef;
                    font-size: 20px;
                    font-family: FontAwesome;
                    display: inline-block;
                    margin-right: .5em;
                    width: 0.6em;
                }

                .footer-section .footer-contact-list ul li a {
                    color: #ecedef;
                    font-weight: 500;
                    position: relative;
                }

                    .footer-section .footer-contact-list ul li a:hover {
                        text-decoration: none;
                        color: #ecedef;
                    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .footer-section .footer-contact-list {
        text-align: center;
    }

        .footer-section .footer-contact-list ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

            .footer-section .footer-contact-list ul li {
                color: #ecedef;
                padding-bottom: 40px;
            }

                .footer-section .footer-contact-list ul li small.contact-info {
                    text-align: center;
                    padding-left: 0px;
                    color: #FFFFFF;
                    word-break: break-all;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 24px;
                }

                .footer-section .footer-contact-list ul li:last-child {
                    padding-bottom: 0;
                }

                .footer-section .footer-contact-list ul li b.phone_number {
                    font-weight: 500;
                    color: #FFFFFF;
                    font-family: Montserrat;
                    font-size: 21px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 40px;
                }

                .footer-section .footer-contact-list ul li.icon-telephone:before {
                    content: "\f095";
                    color: #ecedef;
                    font-size: 21px;
                    font-family: FontAwesome;
                    display: inline-block;
                    margin-right: 0.9em;
                    width: 0.9em;
                }

                .footer-section .footer-contact-list ul li.icon-email:before {
                    content: "\f003";
                    color: #ecedef;
                    font-size: 20px;
                    font-family: FontAwesome;
                    display: inline-block;
                    margin-right: 1.3em;
                    width: 0.6em;
                }

                .footer-section .footer-contact-list ul li a {
                    color: #ecedef;
                    font-weight: 500;
                    position: relative;
                }

                    .footer-section .footer-contact-list ul li a:hover {
                        text-decoration: none;
                        color: #ecedef;
                    }
}

/* Find us Menu */
.footer-section .find-us-menu {
}

    .footer-section .find-us-menu ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

        .footer-section .find-us-menu ul li {
            padding-bottom: 15px;
        }

            .footer-section .find-us-menu ul li a {
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                position: relative;
            }

                .footer-section .find-us-menu ul li a:hover {
                    text-decoration: none;
                }

@media only screen and (max-width: 768px) {
    .footer-section .find-us-menu {
        text-align: center;
        padding-bottom: 10px;
    }

        .footer-section .find-us-menu ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

            .footer-section .find-us-menu ul li {
                padding-bottom: 10px;
            }

                .footer-section .find-us-menu ul li a {
                    color: #FFFFFF;
                    font-family: Montserrat;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 28px;
                    position: relative;
                }

                    .footer-section .find-us-menu ul li a:hover {
                        text-decoration: none;
                    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .footer-section .find-us-menu {
        text-align: center;
        padding-bottom: 10px;
    }

        .footer-section .find-us-menu ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

            .footer-section .find-us-menu ul li {
                padding-bottom: 10px;
            }

                .footer-section .find-us-menu ul li a {
                    color: #FFFFFF;
                    font-family: Montserrat;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 28px;
                    position: relative;
                }

                    .footer-section .find-us-menu ul li a:hover {
                        text-decoration: none;
                    }
}


.card_menu {
}

    .card_menu ul {
        -moz-column-count: 2;
        -moz-column-gap: 20px;
        -webkit-column-count: 2;
        -webkit-column-gap: 20px;
        column-count: 2;
        column-gap: 20px;
        padding: 0;
        margin: 0;
        list-style: none;
    }

@media only screen and (max-width: 768px) {
    .card_menu ul {
        -moz-column-count: 1;
        -moz-column-gap: 20px;
        -webkit-column-count: 1;
        -webkit-column-gap: 20px;
        column-count: 1;
        column-gap: 20px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .card_menu ul {
        -moz-column-count: 1;
        -moz-column-gap: 20px;
        -webkit-column-count: 1;
        -webkit-column-gap: 20px;
        column-count: 1;
        column-gap: 20px;
    }
}

.card_menu ul li {
    padding-bottom: 13px;
}

    .card_menu ul li a {
        color: #016AB7;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        position: relative;
    }

        .card_menu ul li a:hover {
            text-decoration: none;
            font-weight: 600;
            color: #1C7DAA;
        }


.App-content {
    margin-bottom: 1em;
}

.card-wrapper {
    margin-bottom: 1em;
}

.card-title {
    text-align: center;
    color: #004282;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
}

.card-wrapper .card .card-footer-image {
    height: 71px;
    text-align: right;
    color: #fff;
    box-shadow: none;
}

.card-menu-text {
    margin-left: 0px;
    height: 50%;
    width: 100%;
    color: #1C7DAA;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 2em;
    margin-bottom: 1em;
}

.App-row .report-title{
    text-align: center;
    margin: auto;
    color: #004282;
    padding-bottom: 1em;
}

.footer-section .footer-contact-us {
}

    .footer-section .footer-contact-us p {
        color: #fff;
    }

        .footer-section .footer-contact-us p i {
            font-size: 22px;
            padding-right: 10px;
        }

        .footer-section .footer-contact-us p span {
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 21px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 50px;
        }

            .footer-section .footer-contact-us p span.small-info {
                padding-left: 35px;
                font-size: 14px;
                font-weight: 500;
            }

@media only screen and (max-width: 768px) {
    .footer-section .footer-contact-us {
        text-align: center;
        padding-top: 0;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : landscape) {
    .footer-section .footer-contact-us {
        text-align: center;
        padding-top: 0;
    }
}