
html, body {
  height: 100%;
}

body {
	font-family: 'Montserrat' !important;
	word-wrap: normal;
  font-size: 1rem;
  line-height: 1.35rem;
  margin: 0px;
}

.App-loading {
  margin: 45px auto;
  width: 100%;
  text-align: center;
}

.App-mt-2 {
  margin-top: 1rem;
}

.App-mt-4 {
  margin-top: 4rem;
}

.App-w-100 {
  width: 100%;
}


h1 {
  font-weight: bold;
  color: #006BB7;
  font-size: 2rem;
  line-height: 50px;
}

h2 {
  font-weight: bold;
  color: #006BB7;
  font-size: 1.75rem;
  line-height: 40px;
}

h3 {
  font-size: 1.5rem;
  line-height: 30px;
  color: #333;
  margin-bottom:0;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

a {
  background-color: transparent;
  outline-offset: 0.125em;
  color: #004282;
}

.text-center {
  text-align: center;
}

.page-title {
  line-height: 40px;
  margin-top: 0;
  padding-top: 1.3125rem;
}

.App-container {
  margin: 0px;
}

.App-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 75em;
  padding-left: 1em;
  padding-right: 1em;
}

.App-title {
  margin-left: 10px;
}

.App-row {
  display: flex;
    flex-wrap: wrap;
}

.App-card-info {
  background-color: white;
  -webkit-box-shadow: 0 1px 3px 1px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 15%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 1em;
  overflow: hidden;
  padding: 1.5em;
  position: relative;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  -webkit-transition: all 250ms cubic-bezier(0.46,0.03,0.23,0.98);
  -o-transition: all 250ms cubic-bezier(0.46,0.03,0.23,0.98);
  transition: all 250ms cubic-bezier(0.46,0.03,0.23,0.98);
  width: 100%;
}

.App-card-redux {
  width: fit-content !important;
  font-size: 0.85rem;
}

.App-sections {
  float:left;
  width: 25%;
}

.App-table {
  width: 100%;
  font-size: 14px;
}

.App-info {
  width: max-content;
}

.App-table th {
  color: #006BB7;
  text-align: right;
  vertical-align: top;
  padding: 4px;
}

.App-table td {
  vertical-align: top;
  padding: 4px;
}

.App-alert-block {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #c09853;
  padding-top: 14px;
  padding-bottom: 14px;
}

.App-button {
  background-color: #006BB7;
    border: 1px solid transparent;
    border-radius: 30px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat",Helvetica,Arial,sans-serif;
    font-size: 0.9rem;
    line-height: 1.35em;
    margin: 0 0 1em 1em;
    padding: 10px 35px;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
    vertical-align: baseline;
    *line-height: normal;
    *zoom: 1;
}

.App-button-rnd {
  border-radius: 30px !important;
  font-size: 0.9rem !important;
  line-height: 1.35em !important;
  margin: 0 0 1em 1em !important;
  padding: 10px 35px !important;
}

.App-button-outline {
  background-color: #fff;
    border: 1px solid #006BB7;
    border-radius: 30px;
    color: #006BB7;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat",Helvetica,Arial,sans-serif;
    font-size: 0.9rem;
    line-height: 1.35em;
    margin: 0 0 1em 1em;
    padding: 10px 35px;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
    vertical-align: baseline;
    *line-height: normal;
    *zoom: 1;
}

.App-button:hover {
  background-color: #007ed9;
  border-color: #ffffff;
  color: #ffffff;
  outline: 0;
}

.App-button-outline:hover {
  background-color: #007ed9;
  border-color: #ffffff;
  color: #ffffff;
  outline: 0;
}


.App-active {
  font-style: italic;
  font-weight: bold !important;
}

.App-sec-nav {
  display: block !important;
  list-style: none;
  padding: 0;
  margin: 0;
}

.App-sec-nav-item {
  line-height: 24.5px;
  position: relative;
}

.App-sec-nav-link {
  border-bottom: solid 1px #ebebeb;
  display: inline-block;
  width: 100%;
  font-weight: 400;
  padding: .75em 0;
  font-size: 1rem;
  color: #006BB7;
}

.App-top-button {
  width: 100%;
  position: relative;
  height: max-content;
  text-align: right;
}


.App-subsection-decription {
  margin-top:25px;
  color: #333;
  font-size: 1rem;
}

.App-core-label {
  color: #333;
  font-weight: bold;
}

.App-numbering {
  color: #333;
  font-weight: bold;
  margin-right: 10px;
}

.App-question {
  margin-top:15px;
  color: #333;
  font-size: 1.1rem;
}

.App-radio-label {

  margin-top: 10px;
  display: block;
  color: #3a5466;
  font-size: 1.15rem;
}

.App-textarea-label {
  margin-top: 10px;
  margin-left: 5px;
  display: block;
  color: #3a5466;
  font-size: 1.15rem;
}

.App-textarea {
  width: 100%;
}

.App-input {
  border: 1px solid #b8b8b8;
  color: #3a5466;
  font-size: 1em;
  padding: 0.875em;
  width: 95%;
  margin: 10px 2px;
}

.App-input-date {
  border: 1px solid #b8b8b8;
  color: #3a5466;
  font-size: 1em;
  padding: 0.875em;
  margin: 10px 2px;
}

.App-loading-content {
  height: 600px;
}

.App-upload-list .MuiListItem-root {
  max-width: max-content;
}

.App-upload-list .MuiListItem-root .MuiListItemText-primary {
  max-width: 200px;
  display: inline-block;
  word-wrap: break-word;
}

.App-upload-container {
  margin-top: 10px;
}

.App-Upload-button {
  margin-left: 10px;
}

.home-link {
  margin-top: 1rem;
}

.association-info table {
  width: 100%;
}

.association-info tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.App-question-list {
  width: 90vw;
}

.MuiTabs-scroller {
  max-width: 100vw;
  overflow-x: auto !important;
}

.MuiButtonBase-root {
  min-width: 6rem !important;
}

@media screen and (min-width: 768px) {
  .home-link {
  }

  .association-info table {
    width: max-content;
  }

  .association-info tr {
    display: table-row;
  }

  .App-upload-list {
    min-width: max-content;
  }

  .App-upload-list .MuiListItem-root .MuiListItemText-primary {
    max-width: max-content;
  }
}
